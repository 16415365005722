import React, { Fragment } from "react"
import {graphql, Link} from "gatsby"
import Seo from "gatsby-plugin-wpgraphql-seo"

import Layout from "../components/layout"
import Sidebar from "../components/sidebar";

import "../styles/post.scss"
import {GatsbyImage, StaticImage} from "gatsby-plugin-image";

export default function Post({ data, pageContext, location }) {
  const post = data?.wpPost

  return (
    <Fragment>
      <Seo post={post} />
      <Layout location={location}>
        <div className="page-wrapper">
          <div className="single-post-wrapper">
            <div className="pagination-post">
              <Link className="back-to-news" to="/news"><span className="previous "></span> Back To News</Link>
              <div className="posts-pagination">
                <Link className={"previous-post " + (!pageContext.previous ? 'inactive' : '')} to={pageContext.previous}>
                  <span className="previous"></span>
                   <p>  previous</p>
                </Link>
                <Link className={"next-post " + (!pageContext.next ? 'inactive' : '')} to={pageContext.next}>
                  <p>next </p>
                  <span className="next"></span>
                </Link>
              </div>
            </div>
            <div className="featured-image">
              <GatsbyImage alt={post?.featuredImage?.node?.altText} image={post?.featuredImage?.node?.localFile.childImageSharp?.gatsbyImageData} />
            </div>
            <h2 className="title">{post?.title}</h2>
            <div className="date">{post?.date}</div>
            <div className="description" dangerouslySetInnerHTML={{ __html: post?.content }} />
            <div className="share-post">
              Share this post
              <div className="share-post-buttons">
                <Link className="facebook" to={`https://www.facebook.com/sharer/sharer.php?u=${process.env.GATSBY_SITE_URL}` + location?.pathname}>
                  <StaticImage src="../images/facebook-icon.png" />
                </Link>
                <Link className="twitter" to={`https://twitter.com/intent/tweet?text=${process.env.GATSBY_SITE_URL}` + location?.pathname}>
                  <StaticImage src="../images/twitter-icon.png" />
                </Link>
                <Link className="pinterest" to={`https://pinterest.com/pin/create/button/?url=${process.env.GATSBY_SITE_URL}` + location?.pathname}>
                  <StaticImage src="../images/pinterest-icon.png" />
                </Link>
              </div>
            </div>
          </div>
          <div className="sidebar-wrapper">
            <Sidebar />
          </div>
        </div>
      </Layout>
    </Fragment>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      seo {
       title
       metaDesc
       focuskw
       metaKeywords
       opengraphTitle
       opengraphDescription
       opengraphImage {
         altText
         sourceUrl
         srcSet
       }
       twitterTitle
       twitterDescription
       twitterImage {
         altText
         sourceUrl
         srcSet
       }
       canonical
       cornerstone
       schema {
         articleType
         pageType
         raw
       }
      }
      title
      content
      date(formatString: "MMMM DD, YYYY")
      excerpt
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`

// export async function config() {
//   return ({ params }) => {
//     return {
//       defer: true,
//     }
//   }
// }
